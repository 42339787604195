var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alegra-full-plans-renewed__table-wrapper tw-hidden lg:tw-block"},[_c('div',{staticClass:"alegra-full-plans-renewed__table-container"},[_c('div',{staticClass:"alegra-full-plans-renewed__table-head tw-mx-auto"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-px-0 alegra-full-plans-renewed__head-container"},[_c('div',{staticClass:"alegra-full-plans-renewed__head-fill"}),_c('div',{staticClass:"alegra-full-plans-renewed__head tw-w-full tw-flex xl:tw-gap-4"},_vm._l((_vm.levels),function(level,key){return _c('div',{key:key,staticClass:"alegra-full-plans-renewed__plan-head tw-flex tw-flex-col tw-relative alegra-full-plans-renewed__plan-head--text-center"},[_c('p',{class:`alegra-full-plans-renewed__name tw-m-0 tw-font-semibold tw-font-inter alegra--theme-${level.theme}`}),_c('p',{class:`alegra-full-plans-renewed__cta alegra-button tw-relative tw-z-10 tw-m-0 tw-mb-4 tw-w-fit tw-text-center tw-self-center alegra-cta--theme-${level.theme}`},[_vm._v(" "+_vm._s(level.title)+" ")]),_c('p',{staticClass:"alegra-full-plans-renewed__after-ctas tw-m-0 tw-font-medium tw-font-lexend"},[_vm._v(" "+_vm._s(level.refers)+" ")])])}),0)])]),_c('div',{staticClass:"alegra-full-plans-renewed__table-body tw-mx-auto"},[_c('div',{staticClass:"alegra-full-plans-renewed__body"},_vm._l((_vm.benefits),function(benefit,key){return _c('div',{key:key,staticClass:"alegra-full-plans-renewed__feature tw-flex tw-justify-between"},[_c('div',{staticClass:"alegra-full-plans-renewed__feature-name-container",class:[
              key === 0
                ? 'alegra-full-plans-renewed__first-feature-name-container'
                : '',
              key === _vm.benefits.length - 1
                ? 'alegra-full-plans-renewed__last-feature-name-container'
                : '',
            ]},[_c('p',{staticClass:"alegra-full-plans-renewed__feature-name tw-m-0 tw-py-6 tw-text-white tw-font-lexend tw-text-left tw-font-medium",staticStyle:{"font-size":"16px","font-weight":"600"}},[_vm._v(" "+_vm._s(benefit.title)),_c('br'),_c('span',{staticClass:"font-weight-400",staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(benefit.description))])])]),_c('div',{staticClass:"alegra-full-plans-renewed__feature-content-container tw-flex tw-items-center tw-w-full",class:[
              key === 0
                ? 'alegra-full-plans-renewed__first-feature-content-container'
                : '',
              key === _vm.benefits.length - 1
                ? 'alegra-full-plans-renewed__last-feature-content-container'
                : '',
            ]},_vm._l((benefit.details),function(detail,k){return _c('p',{key:k,staticClass:"alegra-full-plans-renewed__feature-data-container tw-py-6 tw-m-0 tw-text-center"},[(!detail.check)?_c('span',{staticClass:"alegra-full-plans-renewed__feature-data tw-text-grey-blue tw-font-inter tw-font-normal",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(detail.value)+" ")]):_c('span',[_c('img',{attrs:{"src":_vm.checked,"alt":"Checked"}})])])}),0)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }