<script>
import checked from "../assets/checked.svg";

export default {
  props: {
    version: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked,
      levels: [
        {
          refers: "3 referidos",
          theme: "alegra-cta--theme-gray",
          stage: "Nivel 1",
          benefits: [
            {
              id: 0,
              title: "20% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "por 4 meses"
            },
            {
              id: 1,
              title: "25% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "-"
            },
            {
              id: 2,
              title: "Cantidad de usuarios o bodegas",
              description: "Extras y sin costo adicional",
              reward: "1"
            },
            {
              id: 3,
              title: "3 meses gratis en Alegra Nómina",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-"
            },
            {
              id: 4,
              title: "3 meses gratis en Alegra Tienda",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-"
            },
            {
              id: 5,
              title: "3 meses gratis en Alegra POS",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-"
            },
            {
              id: 6,
              title: "Entrar a nuestra Red de Profesionales",
              description: "Contadores referidos por Alegra",
              reward: "-"
            },
            {
              id: 7,
              title: "1 año gratis en Alegra Contabilidad",
              description: "Para una cuenta nueva en plan Pyme",
              reward: "-"
            },
            {
              id: 8,
              title: "Curso de Marketing Digital",
              description: "Diseñado para Contadores",
              reward: "-"
            },
            {
              id: 9,
              title: "Curso técnico para Contadores",
              description: "Especializado en contabilidad y tributación",
              reward: "-"
            },
            {
              id: 10,
              title: "Ser parte de los ponentes de Alegra",
              description: "Para webinars, videos y mucho más",
              reward: "-"
            },
          ]
        },
        {
          refers: "5 referidos",
          theme: "alegra-cta--theme-green",
          stage: "Nivel 2",
          benefits: [
            {
              id: 0,
              title: "20% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "por 4 meses"
            },
            {
              id: 1,
              title: "25% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "-"
            },
            {
              id: 2,
              title: "Cantidad de usuarios o bodegas",
              description: "Extras y sin costo adicional",
              reward: "3"
            },
            {
              id: 3,
              title: "3 meses gratis en Alegra Nómina",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 4,
              title: "3 meses gratis en Alegra Tienda",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 5,
              title: "3 meses gratis en Alegra POS",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 6,
              title: "Entrar a nuestra Red de Profesionales",
              description: "Contadores referidos por Alegra",
              reward: "-"
            },
            {
              id: 7,
              title: "1 año gratis en Alegra Contabilidad",
              description: "Para una cuenta nueva en plan Pyme",
              reward: "-"
            },
            {
              id: 8,
              title: "Curso de Marketing Digital",
              description: "Diseñado para Contadores",
              reward: "-"
            },
            {
              id: 9,
              title: "Curso técnico para Contadores",
              description: "Especializado en contabilidad y tributación",
              reward: "-"
            },
            {
              id: 10,
              title: "Ser parte de los ponentes de Alegra",
              description: "Para webinars, videos y mucho más",
              reward: "-"
            },
          ]
        },
        {
          refers: "10 referidos",
          theme: "alegra-cta--theme-primary",
          stage: "Nivel 3",
          benefits: [
            {
              id: 0,
              title: "20% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "por 4 meses"
            },
            {
              id: 1,
              title: "25% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "-"
            },
            {
              id: 2,
              title: "Cantidad de usuarios o bodegas",
              description: "Extras y sin costo adicional",
              reward: "5"
            },
            {
              id: 3,
              title: "3 meses gratis en Alegra Nómina",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 4,
              title: "3 meses gratis en Alegra Tienda",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 5,
              title: "3 meses gratis en Alegra POS",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 6,
              title: "Entrar a nuestra Red de Profesionales",
              description: "Contadores referidos por Alegra",
              reward: "-",
              checked: true
            },
            {
              id: 7,
              title: "1 año gratis en Alegra Contabilidad",
              description: "Para una cuenta nueva en plan Pyme",
              reward: "-",
              checked: true
            },
            {
              id: 8,
              title: "Curso de Marketing Digital",
              description: "Diseñado para Contadores",
              reward: "-"
            },
            {
              id: 9,
              title: "Curso técnico para Contadores",
              description: "Especializado en contabilidad y tributación",
              reward: "-"
            },
            {
              id: 10,
              title: "Ser parte de los ponentes de Alegra",
              description: "Para webinars, videos y mucho más",
              reward: "-"
            },
          ]
        },
        {
          refers: "20 referidos",
          theme: "alegra-cta--theme-blue",
          stage: "Nivel 4",
          benefits: [
            {
              id: 0,
              title: "20% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "-"
            },
            {
              id: 1,
              title: "25% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "por 6 meses"
            },
            {
              id: 2,
              title: "Cantidad de usuarios o bodegas",
              description: "Extras y sin costo adicional",
              reward: "7"
            },
            {
              id: 3,
              title: "3 meses gratis en Alegra Nómina",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 4,
              title: "3 meses gratis en Alegra Tienda",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 5,
              title: "3 meses gratis en Alegra POS",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 6,
              title: "Entrar a nuestra Red de Profesionales",
              description: "Contadores referidos por Alegra",
              reward: "-",
              checked: true
            },
            {
              id: 7,
              title: "1 año gratis en Alegra Contabilidad",
              description: "Para una cuenta nueva en plan Pyme",
              reward: "-",
              checked: true
            },
            {
              id: 8,
              title: "Curso de Marketing Digital",
              description: "Diseñado para Contadores",
              reward: "-",
              checked: true
            },
            {
              id: 9,
              title: "Curso técnico para Contadores",
              description: "Especializado en contabilidad y tributación",
              reward: "1 por año"
            },
            {
              id: 10,
              title: "Ser parte de los ponentes de Alegra",
              description: "Para webinars, videos y mucho más",
              reward: "-"
            },
          ]
        },
        {
          refers: "30 referidos",
          theme: "alegra-cta--theme-enterprise",
          stage: "Nivel 5",
          benefits: [
            {
              id: 0,
              title: "20% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "-"
            },
            {
              id: 1,
              title: "25% OFF",
              description: "En cuentas nuevas de Alegra Contabilidad",
              reward: "por 6 meses"
            },
            {
              id: 2,
              title: "Cantidad de usuarios o bodegas",
              description: "Extras y sin costo adicional",
              reward: "9"
            },
            {
              id: 3,
              title: "3 meses gratis en Alegra Nómina",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 4,
              title: "3 meses gratis en Alegra Tienda",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 5,
              title: "3 meses gratis en Alegra POS",
              description: "Periodo de prueba para cuentas nuevas",
              reward: "-",
              checked: true
            },
            {
              id: 6,
              title: "Entrar a nuestra Red de Profesionales",
              description: "Contadores referidos por Alegra",
              reward: "-",
              checked: true
            },
            {
              id: 7,
              title: "1 año gratis en Alegra Contabilidad",
              description: "Para una cuenta nueva en plan Pyme",
              reward: "-",
              checked: true
            },
            {
              id: 8,
              title: "Curso de Marketing Digital",
              description: "Diseñado para Contadores",
              reward: "-",
              checked: true
            },
            {
              id: 9,
              title: "Curso técnico para Contadores",
              description: "Especializado en contabilidad y tributación",
              reward: "2 por año"
            },
            {
              id: 10,
              title: "Ser parte de los ponentes de Alegra",
              description: "Para webinars, videos y mucho más",
              reward: "-",
              checked: true
            },
          ]
        }
      ]
    }
  },
  mounted() {
    if (! this.verifyVersion) {
      this.levels = this.filterValues
    }
  },
  computed: {
    verifyVersion() {
      let ver = this.version.toLowerCase()

      return ver === 'colombia' || ver === 'col'
    },
    filterValues() {
      return this.levels.map(level => {
        return {
          ...level,
          benefits: level.benefits.filter(benefit => benefit.id !== 3)
        }
      })
    }
  },
  methods: {
    toggleData(e) {
      const parent = e.target.parentElement.parentElement.parentElement;
      const child = parent.lastChild;

      if (parent.classList.contains("showed")) {
        parent.classList.remove("showed");
        child.classList.remove("showed");
        e.target.classList.remove("opened");
        return;
      }

      parent.classList.add("showed");
      child.classList.add("showed");
      e.target.classList.add("opened");
    }
  }
}
</script>

<template>
  <div class="alegra-full-plans-renewed__table-responsive-wrapper lg:tw-hidden">
    <div
      class="alegra-full-plans-renewed__table-responsive-container tw-flex tw-flex-col tw-gap-y-10"
    >
      <div
        v-for="(level, key) in levels"
        :key="key"
        class="alegra-full-plans-renewed__mobile-plan-wrapper tw-w-11/12 tw-mx-auto"
      >
        <div
          class="alegra-full-plans-renewed__mobile-plan-head-wrapper tw-flex tw-items-center tw-justify-between tw-p-6"
        >
          <div
            class="alegra-full-plans-renewed__mobile-plan-head__name-container"
          >
            <p
              class="alegra-full-plans-renewed__mobile-plan-head__after_ctas tw-m-0 tw-font-lexend tw-font-medium tw-text-grey-blue tw-flex tw-items-center"
            >
              {{ level.refers }}
            </p>
          </div>
          <div
            class="alegra-full-plans-renewed__mobile-plan-head__buttons-container tw-flex tw-flex-col tw-items-end"
          >
            <div
              class="alegra-full-plans-renewed__mobile-plan-head__button-container tw-relative alegra--background-gray"
              @click="toggleData"
            >
              <div
                class="alegra-full-plans-renewed__mobile-plan-head__button tw-relative"
              ></div>
            </div>
            <p
              class="alegra-full-plans-renewed__cta alegra-button tw-relative tw-z-10 tw-m-0 tw-mt-4"
              :class="level.theme"
            >
             {{ level.stage }}
            </p>
          </div>
        </div>
        <div
          class="alegra-full-plans-renewed__mobile-plan-body-wrapper tw-mx-6"
          style="{ background: linear-gradient( 104.7deg, #00535e -2.13%, #0b616c 178.46%) }"
        >
          <div
            v-for="(benefit, k) in level.benefits"
            :key="k"
            class="alegra-full-plans-renewed__mobile-plan-feature-container tw-flex tw-justify-between"
          >
            <div
              class="alegra-full-plans-renewed__mobile-feature-name-container"
            >
              <p
                class="alegra-full-plans-renewed__mobile-feature-name tw-pl-4 tw-pr-4 tw-py-6 tw-text-white tw-font-lexend tw-font-medium tw-m-0"
              >
                {{ benefit.title  }}<br /><span
                  class="font-weight-400"
                  style="font-size: 11px"
                  >{{ benefit.description }}</span
                >
              </p>
            </div>
            <div
              class="alegra-full-plans-renewed__mobile-feature-value-wrapper tw-w-full tw-flex tw-justify-center tw-items-center"
              :class="[k === 0 ? 'alegra-full-plans-renewed__mobile-first-feature-value-wrapper': '', k === level.benefits.length - 1 ? 'alegra-full-plans-renewed__mobile-last-feature-value-wrapper' : '' ]"
            >
              <p
                class="alegra-full-plans-renewed__mobile-feature-value-container tw-pl-4 tw-py-6 tw-m-0 tw-w-full tw-font-inter tw-font-normal tw-text-grey-blue tw-text-center"
              >
                <span v-if="! benefit.checked" class="alegra-full-plans-renewed__mobile-feature-value">
                  {{ benefit.reward }}
                </span>
                <span v-else ><img :src="checked" alt="Checked" /> </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
