<script>
import checked from "../assets/checked.svg";

export default {
  props: {
    version: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      benefits: [
        {
          id: 0,
          title: "20% OFF",
          description: "En cuentas nuevas de Alegra Contabilidad",
          details: [
            {
              check: false,
              value: "4 meses",
            },
            {
              check: false,
              value: "4 meses",
            },
            {
              check: false,
              value: "4 meses",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
          ],
        },
        {
          id: 1,
          title: "25% OFF",
          description: "En cuentas nuevas de Alegra Contabilidad",
          details: [
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "6 meses",
            },
            {
              check: false,
              value: "6 meses",
            },
          ],
        },
        {
          id: 2,
          title: "Cantidad de usuarios o bodegas",
          description: "Extras y sin costo adicional",
          details: [
            {
              check: false,
              value: "1",
            },
            {
              check: false,
              value: "3",
            },
            {
              check: false,
              value: "5",
            },
            {
              check: false,
              value: "7",
            },
            {
              check: false,
              value: "9",
            },
          ],
        },
        {
          id: 3,
          title: "3 meses gratis en Alegra Nómina",
          description: "Periodo de prueba para cuentas nuevas",
          details: [
            {
              check: false,
              value: "-",
            },
            {
              check: true,
              value: "4 meses",
            },
            {
              check: true,
              value: "4 meses",
            },
            {
              check: true,
              value: "-",
            },
            {
              check: true,
              value: "-",
            },
          ],
        },
        {
          id: 4,
          title: "3 meses gratis en Alegra Tienda",
          description: "Periodo de prueba para cuentas nuevas",
          details: [
            {
              check: false,
              value: "-",
            },
            {
              check: true,
              value: "4 meses",
            },
            {
              check: true,
              value: "4 meses",
            },
            {
              check: true,
              value: "-",
            },
            {
              check: true,
              value: "-",
            },
          ],
        },
        {
          id: 5,
          title: "3 meses gratis en Alegra POS",
          description: "Periodo de prueba para cuentas nuevas",
          details: [
            {
              check: false,
              value: "-",
            },
            {
              check: true,
              value: "4 meses",
            },
            {
              check: true,
              value: "4 meses",
            },
            {
              check: true,
              value: "-",
            },
            {
              check: true,
              value: "-",
            },
          ],
        },
        {
          id: 6,
          title: "Entrar a nuestra Red de Profesionales",
          description: "Contadores referidos por Alegra",
          details: [
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: true,
              value: "4 meses",
            },
            {
              check: true,
              value: "-",
            },
            {
              check: true,
              value: "-",
            },
          ],
        },
        {
          id: 7,
          title: "1 año gratis en Alegra Contabilidad",
          description: "Para una cuenta nueva en plan Pyme",
          details: [
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: true,
              value: "4 meses",
            },
            {
              check: true,
              value: "-",
            },
            {
              check: true,
              value: "-",
            },
          ],
        },
        {
          id: 8,
          title: "Curso de Marketing Digital",
          description: "Diseñado para Contadores",
          details: [
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: true,
              value: "-",
            },
            {
              check: true,
              value: "-",
            },
          ],
        },
        {
          id: 9,
          title: "Curso técnico para Contadores",
          description: "Especializado en contabilidad y tributación",
          details: [
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "1 por año",
            },
            {
              check: false,
              value: "2 por año",
            },
          ],
        },
        {
          id: 10,
          title: "Ser parte de los ponentes de Alegra",
          description: "Para webinars, videos y mucho más",
          details: [
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: false,
              value: "-",
            },
            {
              check: true,
              value: "-",
            },
          ],
        },
      ],
      levels: [
        {
          title: "Nivel 1",
          refers: "3 Referidos",
          theme: "gray",
        },
        {
          title: "Nivel 2",
          refers: "5 Referidos",
          theme: "green",
        },
        {
          title: "Nivel 3",
          refers: "10 Referidos",
          theme: "primary",
        },
        {
          title: "Nivel 4",
          refers: "20 Referidos",
          theme: "blue",
        },
        {
          title: "Nivel 5",
          refers: "30 Referidos",
          theme: "enterprise",
        },
      ],
      checked
    }
  },
  mounted() {
    if (! this.verifyVersion) {
      this.benefits = this.filterValues
    }
  },
  computed: {
    verifyVersion() {
      let ver = this.version.toLowerCase()

      return ver === 'colombia' || ver === 'col'
    },
    filterValues() {
      return this.benefits.filter(benefit => benefit.id !== 3)
    }
  }
}
</script>

<template>
   <div class="alegra-full-plans-renewed__table-wrapper tw-hidden lg:tw-block">
    <div class="alegra-full-plans-renewed__table-container">
      <div class="alegra-full-plans-renewed__table-head tw-mx-auto">
        <div class="tw-flex tw-justify-between tw-px-0 alegra-full-plans-renewed__head-container">
          <div class="alegra-full-plans-renewed__head-fill"></div>
          <div
            class="alegra-full-plans-renewed__head tw-w-full tw-flex xl:tw-gap-4"
          >
            <div
              v-for="(level, key) in levels"
              :key="key"
              class="alegra-full-plans-renewed__plan-head tw-flex tw-flex-col tw-relative alegra-full-plans-renewed__plan-head--text-center"
            >
              <p
                :class="`alegra-full-plans-renewed__name tw-m-0 tw-font-semibold tw-font-inter alegra--theme-${level.theme}`"
              ></p>
              <p
                :class="`alegra-full-plans-renewed__cta alegra-button tw-relative tw-z-10 tw-m-0 tw-mb-4 tw-w-fit tw-text-center tw-self-center alegra-cta--theme-${level.theme}`"
              >
                {{ level.title }}
              </p>
              <p
                class="alegra-full-plans-renewed__after-ctas tw-m-0 tw-font-medium tw-font-lexend"
              >
                {{ level.refers }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="alegra-full-plans-renewed__table-body tw-mx-auto">
        <div class="alegra-full-plans-renewed__body">
          <div
            v-for="(benefit, key) in benefits"
            :key="key"
            class="alegra-full-plans-renewed__feature tw-flex tw-justify-between"
          >
            <div
              class="alegra-full-plans-renewed__feature-name-container"
              :class="[
                key === 0
                  ? 'alegra-full-plans-renewed__first-feature-name-container'
                  : '',
                key === benefits.length - 1
                  ? 'alegra-full-plans-renewed__last-feature-name-container'
                  : '',
              ]"
            >
              <p
                class="alegra-full-plans-renewed__feature-name tw-m-0 tw-py-6 tw-text-white tw-font-lexend tw-text-left tw-font-medium"
                style="font-size: 16px; font-weight: 600;"
              >
                {{ benefit.title }}<br /><span
                  class="font-weight-400"
                  style="font-size: 11px"
                  >{{ benefit.description }}</span
                >
              </p>
            </div>
            <div
              class="alegra-full-plans-renewed__feature-content-container tw-flex tw-items-center tw-w-full"
              :class="[
                key === 0
                  ? 'alegra-full-plans-renewed__first-feature-content-container'
                  : '',
                key === benefits.length - 1
                  ? 'alegra-full-plans-renewed__last-feature-content-container'
                  : '',
              ]"
            >
              <p
                v-for="(detail, k) in benefit.details"
                :key="k"
                class="alegra-full-plans-renewed__feature-data-container tw-py-6 tw-m-0 tw-text-center"
                style=""
              >
                <span
                  v-if="!detail.check"
                  class="alegra-full-plans-renewed__feature-data tw-text-grey-blue tw-font-inter tw-font-normal"
                  style="font-size: 16px"
                >
                  {{ detail.value }}
                </span>
                <span v-else>
                  <img :src="checked" alt="Checked" />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>