<script>
import DesktopTable from './DesktopTable.vue';
import MobileTable from './MobileTable.vue';

export default {
  props: {
    version: {
      type: String,
      default: ''
    }
  },
  components: {
    DesktopTable, MobileTable
  }
}
</script>

<template>
  <div class="alegra-flex-container tw-flex-col tw-items-center tw-mb-20 alegra-full-plans-features-renewed-section">
    <div class="alegra-full-plans-features-renewed__content-wrapper">
      <DesktopTable :version="version"/>
      <MobileTable :version="version"/>
    </div>
  </div>
</template>

<style src="../assets/index.css"></style>